import { useState, useEffect } from 'react';
import useStore from '@/store/main/action';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '@/constants';
import { simpleFetch } from '@/utils/simpleFetch';

export interface Pin {
    pin1: string;
    pin2: string;
    pin3: string;
    pin4: string;
}

interface fetchPin {
    pin: boolean
}

const usePin = () => {
    const navigate = useNavigate();

    const { userInfo, setSurveyToken, surveytoken,} = useStore();
    const initialStatePin: Pin = {
        pin1: '',
        pin2: '',
        pin3: '',
        pin4: '',
    };
    const [pinValue, setpinValue] = useState(initialStatePin);

    const [loading, setloading] = useState(false);
    const [status, setstatus] = useState(0);

    const handleGoBack = () => {
        navigate(`encuestas/${surveytoken}`);
    };

    /* 
		status: 
		0 - none
		2 - done
		3 - not found 
		4 - error
    */
    const handleError = () => {
        setpinValue(initialStatePin);
        const input = document.getElementById(`pin1`);
        input?.focus();
    };

    const handleCheckPin = async () => {
        setloading(true);

        if (pin !== '' && pin.length >= 4) {
            const helperPin = pin.substring(0, 4);
            const datos = {
                cPin: helperPin,
                nUsuario: userInfo.nUsuario,
            };
            
            const response = await simpleFetch<fetchPin>({
                url: `${API_URL}Valida/Pin`,
                method: 'POST',
                params: datos,
                headers: { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}`}
            })

            if(!response.success){
                handleError();
                setstatus(4);
            } else if (response.success && response.data.pin) {
                setstatus(2);
                setSurveyToken(null);
            } else {
                setstatus(3);
                handleError();
            }
            setloading(false);
        }
    };
    const pin = pinValue.pin1 + pinValue.pin2 + pinValue.pin3 + pinValue.pin4;

    const handlePinvalue = (i: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;

        const copy = {
            ...pinValue,
        };

        const copyKey = `pin${(i + 1)}` as keyof typeof initialStatePin;
        const helper = value.replace(copy[copyKey], '');

        if (value.length > 1 && helper !== '') {
            copy[copyKey] = helper;
        } else {
            copy[copyKey] = value[0] || '';
        }

        setpinValue(copy);

        if (i < 3 && value) {
            const input = document.getElementById(`pin${i + 2}`);
            input?.focus();
        }
    };

    useEffect(() => {
        if (pin.length === 4) {
            handleCheckPin();
        }
    }, [pin]);

    return {
        handlePinvalue,
        pinValue,
        status,
        surveytoken,
        setpinValue,
        handleGoBack,
        loading,
    };
};

export default usePin;
