import colors from '@/styles/theme/colors';
import isPropValid from '@emotion/is-prop-valid';
import { ShouldForwardProp } from 'styled-components';
// import _ from 'lodash';

export const getIsTouchDevice = () => {
    if (typeof window !== 'undefined') {
        const isTablet = window.matchMedia('(pointer: coarse)').matches;
        return 'ontouchstart' in window || isTablet;
    }
    return false;
};

// export const getAutocompleteValues = (dataEncuestas, dataUsuario) => {
//     return _.filter(
//         dataEncuestas,
//         (o) =>
//             _.filter(dataUsuario.UsuariosEncuestas, (e) => e.EncuestaAsignada.nEncuesta === o.nEncuesta).length === 0,
//     );
// };

// export const getUsuariosEncuestasDelete = (dataUsuario) => {
//     return _.filter(
//         dataUsuario.UsuariosEncuestasBack,
//         (o) => _.filter(dataUsuario.UsuariosEncuestas, (e) => e.nUsuarioEncuesta === o.nUsuarioEncuesta).length === 0,
//     );
// };

export const baseColor = colors.primary;

export const initialState = {
    userInfo: {},
    primaryColor: '#970F0F',
    icon: '/grupoPanamaH.png',
    backgroundImg: '',
    cDescripcion: '',
    loading: true,
    back: false,
    surveytoken: null,
};

// Registro de Usuarios

// export const getAutocompleteValuesRegistroUsuarios = (dataEncuestas, dataEmpresas, asginaciones, showEncuestas) => {
//     const idIndex = showEncuestas ? 'nEncuesta' : 'id';
//     return _.filter(
//         showEncuestas ? dataEncuestas : dataEmpresas,
//         (o) => _.find(asginaciones, (a) => a[idIndex] === o[idIndex]) === undefined,
//     );
// };

export const getErrorNombreUsuarioRegistro = (nombreUsuario: string) => {
    return (
        nombreUsuario !== '' &&
        nombreUsuario.length < 30 &&
        nombreUsuario.match(/^(?=.{0,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/) === null &&
        'El nombre de usuario es inválido.'
    );
};

export const getErrorTipoUsuarioRegistro = (tipoUsuario: string) => {
    return tipoUsuario !== '' && tipoUsuario.toString().match(/^[0-9]*$/) === null;
};

export const getErrorPasswordRegistro = (password: string) => {
    return (
        password !== '' &&
        password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/) === null &&
        'Contraseña incorrecta, debe tener mínimo 8 caracteres, una letra mayúscula, una letra minúscula y un número.'
    );
};
// const reg = /^[0-9]*$/;
// export const getErrorPin = (pin) => {
//     return (
//         ((pin !== '' && reg.test(pin) === false) || pin.length > 4) &&
//         'El pin debe contener máximo 4 dígitos y sólo números.'
//     );
// };

// export const getDisabledGuardarRegistro = (nombreUsuario, tipoUsuario, password, pin, asginaciones) => {
//     const errorNombreUsuarioRegistro =
//         nombreUsuario !== '' &&
//         (nombreUsuario.length > 30 ||
//             nombreUsuario.match(/^(?=.{0,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/) === null);

//     const errorTipoUsuarioRegistro = tipoUsuario !== '' && tipoUsuario.toString().match(/^[0-9]*$/) === null;

//     const errorPasswordRegistro =
//         password !== '' &&
//         password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[]-]{8,25}$/) === null;

//     const errorPin = (pin !== '' && reg.test(pin) === false) || pin.length > 4;

//     return {
//         helperNombreUsuarioRegistro: {
//             helperText: errorNombreUsuarioRegistro ? 'El nombre de usuario es inválido.' : '',
//             error: errorNombreUsuarioRegistro,
//         },
//         helperTipoUsuarioRegistro: {
//             helperText: null,
//             error: errorTipoUsuarioRegistro,
//         },
//         helperPasswordRegistro: {
//             helperText: errorPasswordRegistro
//                 ? 'Contraseña incorrecta, debe tener mínimo 8 caracteres, una letra mayúscula, una letra minúscula y un número.'
//                 : '',
//             error: errorPasswordRegistro,
//         },
//         helperPin: {
//             helperText: errorPin ? 'El pin debe contener máximo 4 dígitos y sólo números.' : '',
//             error: errorPin,
//         },
//         general:
//             (asginaciones && asginaciones.length === 0) ||
//             nombreUsuario === '' ||
//             tipoUsuario === '' ||
//             password === '' ||
//             pin === '' ||
//             errorNombreUsuarioRegistro ||
//             errorTipoUsuarioRegistro ||
//             errorPasswordRegistro ||
//             errorPin,
//     };
// };

export const getColorsByGrade = (grade: number) => {
    switch (grade) {
        case 0:
            return '#F30004';
        case 1:
            return '#FE0003';
        case 2:
            return '#FF6300';
        case 3:
            return '#FF6200';
        case 4:
            return '#FFA300';
        case 5:
            return '#FFC709';
        case 6:
            return '#FBDD01';
        case 7:
            return '#EDE82A';
        case 8:
            return '#EDE82A';
        case 9:
            return '#95D42D';
        case 10:
            return '#01B947';
    }
};

// const compose =
//     (method: string) =>
//     (...ms: ((x: unknown) => unknown)[]) =>
//         ms.reduceRight((f, g) => (x: unknown) => g(x)[method](f));

// export const composePromises = compose('then');




// This implements the default behavior from styled-components v5
export const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
};
