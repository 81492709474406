import { Pregunta, PreguntasDetalle } from "@/hooks/useSurvey";
import _ from "lodash";

export const handleDisable = (item : Pregunta) => (
	(item.bOpcional) ? false :
	((item.nTipo === 2 || item.nTipo === 1 || item.nTipo === 4) && !item.cValor) || 
	(item.nTipo === 3 && (!item.cValor || item.cValor === '' || item.cValor.length > 600)) ||
	(item.nTipo === 6 && !item.nRespuesta)
)

export const handleTableStepDisable = (details: PreguntasDetalle[]) => _.filter(details, (item) => !item.nValor).length > 0;
