import { useState, useEffect } from 'react';
import { getDisabled } from '@/components/UserInfoForm/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from '@/store/main/action';
import _ from 'lodash';
import { API_URL, SSO_API_URL } from '@/constants';
import { toast } from 'sonner';
import { customFetch, handleRedirectSSO } from '@/utils/Auth';
import { simpleFetch } from '@/utils/simpleFetch';

export interface EncuestaInfo {
    nEncuesta: number;
    cDescripcion: string;
    cMensajeInicial: string;
    Preguntas: Pregunta[];
    PreguntaRespuestas: PreguntaRespuesta[];
    PreguntasDetalles: PreguntasDetalle[];
    Assets: Assets;
    MetaInfo: MetaInfo[];
    Headers: MetaInfo[];
}

export interface Assets {
    cLogo: string;
    cBackgroundImage: string;
    cPrimaryColor: string;
    cSecondaryColor: string;
    cMetaInfoTitulo: string;
}

export interface MetaInfo {
    nEncuestaMetaInfo: number;
    cNombreMetaInfo: string;
    cValor?: string
    nTipo: number;
    nPregunta: number | null;
    bOpcional: boolean;
}

export interface PreguntaRespuesta {
    nPregunta: number;
    nRespuesta: number;
    cDescripcion: string;
    nValor: number;
}

// export interface Pregunta {
export interface Pregunta {
    cSeccion: string;
    nPregunta: number;
    cPregunta: string;
    nTipo: number;
    cValor?: string
    cRespuesta?: string
    nRespuesta?: number;
    bOpcional: boolean | null
    bMostrarSeccion: boolean | null;
}

export interface PreguntasDetalle {
    nPregunta: number;
    nDetalle: number;
    cDescripcion: string;
    nValor?: string
}

interface props {
    validarPorToken?: boolean
}

export type SurveyStepsHandler = <K extends keyof Pregunta>(i: number, index: K, value: Pregunta[K]) => void;
export type SurveyStepsDetailsHandler = <K extends keyof PreguntasDetalle>(i: number, index: K, value: PreguntasDetalle[K]) => void;

const useSurvey = ({ validarPorToken }: props) => {
    const { setAssets, primaryColor, removeAssets, icon, setSurveyToken, cDescripcion, setUser, setIsLogged, setUserInfo } = useStore();

    const navigate = useNavigate();
    const handleMenuPrincipal = () => {
        navigate(`/`);
    };

    const { encuestaId, encuestaToken } = useParams();

    const [dataEncuestas, setdataEncuestas] = useState<EncuestaInfo>();
    const [surveySteps, setsurveySteps] = useState<Pregunta[]>([]);

    const [surveyStepsDetails, setsurveyStepsDetails] = useState<PreguntasDetalle[]>([]);

    const [status, setstatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [metaInfo, setmetaInfo] = useState<MetaInfo[]>([]);
    const [headers, setheaders] = useState<MetaInfo[]>([]);

    const handleReset = () => {
        if (status !== 0) {
            const copy = [...surveySteps];
            copy.forEach((element) => {
                element.cValor = undefined;
                element.nRespuesta = undefined;
            });
            setsurveySteps(copy);

            const copyheaders = [...headers];
            copyheaders.forEach((element) => {
                element.cValor = undefined;
            });
            setheaders(copyheaders);

            const copyMetaInfo = [...metaInfo];
            copyMetaInfo.forEach((element) => {
                element.cValor = undefined;
            });
            setmetaInfo(copyMetaInfo);

            setstatus(headers.length > 0 ? -1 : 0);
        }
    };

    const handleGetEncuesta = async () => {
        try {
            const response = await simpleFetch<EncuestaInfo>({
                url: validarPorToken ? `${API_URL}Obten/EncuestaxToken?cCodigo=${encuestaToken}` : `${API_URL}Obten/Encuesta?cCodigo=${encuestaId}`,
                method: 'POST',
                headers: validarPorToken ? { Token: encuestaToken ?? '' } : {Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS')}`},
            })

            setloading(false)

            if (!response.success) {
                setstatus(response.message === 'not found' ? 5 : 3);
                return;
            }

            const encuesta = response.data;
            setdataEncuestas(encuesta);

            // const endpoint = `${urlLocal}Encuestas/Uploads?file=`;

            if (encuesta?.Headers?.length > 0) {
                setheaders(encuesta.Headers);
                setstatus(-1);
            }

            encuesta.MetaInfo && setmetaInfo(encuesta.MetaInfo);

            encuesta.Assets &&
                setAssets(
                    `#${encuesta.Assets.cPrimaryColor}`,
                    `${encuesta.Assets.cLogo}`,
                    `${encuesta.Assets.cBackgroundImage}`,
                    encuesta.cDescripcion,
                );

            if (Array.isArray(encuesta.Preguntas) && encuesta.Preguntas.length !== 0) {
                setsurveySteps([...encuesta.Preguntas]);
            } else {
                setstatus(5);
                setSurveyToken('');
            }

            encuesta.PreguntasDetalles && setsurveyStepsDetails([...encuesta.PreguntasDetalles]);

            
        } catch (error) {
            setstatus(3)
        }
        
    }

    useEffect(() => {
        removeAssets();

        if (encuestaId ?? encuestaToken) {
            setSurveyToken(encuestaId);

            // simpleFetch<EncuestaInfo>({
            //     url: validarPorToken ? `${API_URL}Obten/EncuestaxToken?cCodigo=${encuestaToken}` : `${API_URL}Obten/Encuesta?cCodigo=${encuestaId}`,
            //     method: 'POST',
            //     headers: validarPorToken ? { Token: encuestaToken ?? '' } : undefined,
            // }).then((response) => {
            //     setloading(false);

            //     if (!response.success) {
            //         setstatus(response && response.data?.[0] === 'not found' ? 5 : 3);
            //         return;
            //     }

            //     const encuesta = response.data;
            //     setdataEncuestas(encuesta);

            //     // const endpoint = `${urlLocal}Encuestas/Uploads?file=`;

            //     if (encuesta?.Headers?.length > 0) {
            //         setheaders(encuesta.Headers);
            //         setstatus(-1);
            //     }

            //     encuesta.MetaInfo && setmetaInfo(encuesta.MetaInfo);

            //     encuesta.Assets &&
            //         setAssets(
            //             `#${encuesta.Assets.cPrimaryColor}`,
            //             `${encuesta.Assets.cLogo}`,
            //             `${encuesta.Assets.cBackgroundImage}`,
            //             encuesta.cDescripcion,
            //         );

            //     if (Array.isArray(encuesta.Preguntas) && encuesta.Preguntas.length !== 0) {
            //         setsurveySteps([...encuesta.Preguntas]);
            //     } else {
            //         setstatus(5);
            //         setSurveyToken('');
            //     }

            //     encuesta.PreguntasDetalles && setsurveyStepsDetails([...encuesta.PreguntasDetalles]);
            // })
            // .catch((error) => {
            //     console.log(error, 'error');
            //     setstatus(3);
            // });
            
            handleGetEncuesta()
            // simpleFetch<EncuestaInfo>({
            //     url: validarPorToken ? `${API_URL}Obten/EncuestaxToken?cCodigo=${encuestaToken}` : `${API_URL}Obten/Encuesta?cCodigo=${encuestaId}`,
            //     method: 'POST',
            //     headers: validarPorToken ? { Token: encuestaToken ?? '' } : undefined,
            // }).then((response) => {
            //         setloading(false);

            //         if (!response.success) {
            //             setstatus(response.data === 'not found' ? 5 : 3);
            //             return;
            //         }

            //         const encuesta = response.data;
            //         setdataEncuestas(encuesta);

            //         // const endpoint = `${urlLocal}Encuestas/Uploads?file=`;

            //         if (encuesta?.Headers?.length > 0) {
            //             setheaders(encuesta.Headers);
            //             setstatus(-1);
            //         }

            //         encuesta.MetaInfo && setmetaInfo(encuesta.MetaInfo);

            //         encuesta.Assets &&
            //             setAssets(
            //                 `#${encuesta.Assets.cPrimaryColor}`,
            //                 `${encuesta.Assets.cLogo}`,
            //                 `${encuesta.Assets.cBackgroundImage}`,
            //                 encuesta.cDescripcion,
            //             );

            //         if (Array.isArray(encuesta.Preguntas) && encuesta.Preguntas.length !== 0) {
            //             setsurveySteps([...encuesta.Preguntas]);
            //         } else {
            //             setstatus(5);
            //             setSurveyToken('');
            //         }

            //         encuesta.PreguntasDetalles && setsurveyStepsDetails([...encuesta.PreguntasDetalles]);
            //     })
            //     .catch((error) => {
            //         console.log(error, 'error');
            //         setstatus(3);
            //     });
        }
    }, [encuestaId, encuestaToken]);

    const handleSurveySteps: SurveyStepsHandler = (i, index, value) => {
        const copy = [...surveySteps];
        copy[i][index] = value;
        setsurveySteps(copy);
    };

    const handleSurveyStepsDetails: SurveyStepsDetailsHandler = (i, index, value) => {
        const copy = [...surveyStepsDetails];
        copy[i][index] = value;
        setsurveyStepsDetails(copy);
    };

    const handleMetaInfo = (i: number, value: string) => {
        const copy = [...metaInfo];
        copy[i].cValor = value;
        setmetaInfo(copy);
    };
    const disabledStart = getDisabled(metaInfo);

    const handleHeaders = (i: number, value: string) => {
        const copy = [...headers];
        copy[i].cValor = value;
        setheaders(copy);
    };
    const disabledStartHeaders = getDisabled(headers);

    const handleDone = async () => {
        setloading(true);
        if (!disabledStart) {
            try {
                const datos = {
                    IDEncuesta: dataEncuestas?.nEncuesta,
                    MetaData: [...headers, ..._.filter(metaInfo, (o) => o.nTipo !== 2)],
                    Respuestas: surveySteps,
                    RespuestasDetalles: surveyStepsDetails,
                    emailImg: icon,
                    primaryColor,
                    cDescripcion,
                };
                //console.log(dataEncuestas.nEncuesta)
                const { success, data } = await simpleFetch({
                    url: validarPorToken ? `${API_URL}Guarda/RespuestasxToken` : `${API_URL}Guarda/Respuestas`,
                    params: datos,
                    method: 'POST',
                    headers: validarPorToken ? { Token: encuestaToken ?? '' } : { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}`},
                });

                if (success) {
                    setstatus(2);
                } else {
                    toast.error('Sucedió un error');

                    simpleFetch({
                        url: `${API_URL}Guarda/LogError`,
                        params: { success, data },
                        method: 'POST',
                        headers: validarPorToken ? { Token: encuestaToken ?? '' } : { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}`},
                    });
                }

                setloading(false);
            } catch (e) {
                console.log(e, 'error');

                await simpleFetch({
                    url: `${API_URL}Guarda/LogError`,
                    params: { Error: String(e) },
                    method: 'POST',
                    headers: validarPorToken ? { Token: encuestaToken ?? '' } : { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}`},
                });

                setstatus(3);
            }
        }
    };

    const handleValidaSesion = async () => {
        
        try {
            const { response } = await customFetch({
                url: `${SSO_API_URL}/Valida/Sesion`,
                method: 'POST',
                // headers: { Authorization: `BEARER ${cSessionToken}` },
                headers: validarPorToken ? {} : { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}`}
            });

            if (!response.Success) {
                setIsLogged(false);
                throw 'Sesión Inválida';
            }

            setUser(response.Data);
            setUserInfo(response.Data);
            setIsLogged(true);
        } catch (error) {
            handleRedirectSSO();
        }
    };

    return {
        surveySteps,
        handleSurveySteps,
        handleSurveyStepsDetails,
        handleDone,
        status,
        loading,
        handleReset,
        setstatus,
        handleMenuPrincipal,
        handleMetaInfo,
        metaInfo,
        primaryColor,
        disabledStart,
        dataEncuestas,
        headers,
        handleHeaders,
        disabledStartHeaders,
        handleValidaSesion
    };
};

export default useSurvey;
