import useSurvey from 'hooks/useSurvey';
import { SurveyWizard, UserInfoForm, ErrorMessage, SuccessMessage, SurveyLayout, Loading } from '@/components';
// import {TransitionContainer} from 'components/GeneralLayout/styled';

interface props {
    validarPorToken?: boolean
}

const SurveyView = ({ validarPorToken } : props) => {
    const {
        surveySteps,
        handleSurveySteps,
        handleSurveyStepsDetails,
        handleDone,
        status,
        loading,
        setstatus,
        handleMenuPrincipal,
        handleMetaInfo,
        handleHeaders,
        metaInfo,
        primaryColor,
        disabledStart,
        headers,
        disabledStartHeaders,
        dataEncuestas,
        handleReset,
        handleValidaSesion
    } = useSurvey({
        validarPorToken
    });

    if (status === -1) {
        return dataEncuestas ? (
            <UserInfoForm
                disabledStart={disabledStartHeaders}
                metaInfo={headers}
                status={0}
                headers={true}
                handleMetaInfo={handleHeaders}
                title={'Por favor, complete el formulario antes de iniciar la encuesta.'}
                setstatus={setstatus}
                handleValidaSesion={handleValidaSesion}
                validaPorToken={validarPorToken}
            />
        ) : (
            <SurveyLayout>
                <Loading />
            </SurveyLayout>
        );
    }
    
    if (status === 0) {
        return dataEncuestas ? (
            <UserInfoForm
                status={1}
                headers={headers.length === 0}
                disabledStart={disabledStart}
                metaInfo={metaInfo}
                handleMetaInfo={handleMetaInfo}
                title={dataEncuestas?.Assets?.cMetaInfoTitulo}
                setstatus={setstatus}
                handleValidaSesion={handleValidaSesion}
                validaPorToken={validarPorToken}
            />
        ) : (
            <SurveyLayout>
                <Loading />
            </SurveyLayout>
        );
    }

    if (status === 2) {
        return <SuccessMessage handleReset={handleReset} />;
    }

    if (status === 3 || status === 5) {
        return (
            <SurveyLayout>
                <ErrorMessage
                    handleClick={handleMenuPrincipal}
                    subtitle={
                        status === 5
                            ? 'Encuesta no encontrada, compruebe que el url es el correcto.'
                            : 'Algo ha salido mal, porfavor intente más tarde.'
                    }
                />
            </SurveyLayout>
        );
    }

    return surveySteps.length > 0 && dataEncuestas?.PreguntasDetalles && dataEncuestas?.PreguntaRespuestas && primaryColor ? (
        <SurveyWizard
            metaInfo={metaInfo}
            handleMenu={() => setstatus(0)}
            disabledNext={loading}
            handleDone={handleDone}
            handleSurveySteps={handleSurveySteps}
            handleSurveyStepsDetails={handleSurveyStepsDetails}
            steps={surveySteps}
            details={dataEncuestas.PreguntasDetalles}
            answers={dataEncuestas.PreguntaRespuestas}
        />
    ) : null;
};

export default SurveyView;
