import { Grid, useMediaQuery } from '@mui/material';
import { Typography } from '../../styled';
import { TextField } from 'components';
import { getIsTouchDevice } from '@/utils';
import { Pregunta, SurveyStepsHandler } from '@/hooks/useSurvey';

interface props {
    step: Pregunta,
    handleSurveySteps: SurveyStepsHandler,
    iStep: number,
}

const TextTareaStep = ({ step, handleSurveySteps, iStep } : props) => {
    const isTouchDevice = getIsTouchDevice();
    const xs = useMediaQuery('(min-width: 500px)');
    const error = step.cRespuesta ? step.cRespuesta.length > 600 : false;

    return (
        <Grid container direction="column" padding="0 5px 0 0">
            <Grid
                justifyContent={isTouchDevice && xs ? 'center' : 'flex-start'}
                padding={isTouchDevice ? '0 0 40px 0' : '0 0 10px 0'}
                container
            >
                <Typography
                    color={'#1b1c1d'}
                    font={isTouchDevice ? (xs ? '27px' : '20px') : undefined}
                    align={isTouchDevice ? 'center' : 'right'}
                    variant={isTouchDevice ? undefined : 'h6'}
                >
                    <strong>{step.cPregunta}</strong>
                </Typography>
            </Grid>
            <TextField
                helperText={error ? 'Excede los 600 caracteres.' : null}
                error={error}
                handleValue={(value) => handleSurveySteps(iStep, 'cValor', value)}
                value={step.cValor ? step.cValor : ''}
            />
        </Grid>
    );
};

export default TextTareaStep;
