import { handleRedirectSSO } from "./Auth";

import { toast } from 'sonner';


interface RetryConfig {
	maxRetries?: number;
	initialDelay?: number;
	maxDelay?: number;
	retryableStatuses?: number[];
	retryableErrors?: string[];
	// Browser-specific options
	checkOnlineStatus?: boolean;
	showUserFeedback?: boolean;
}

interface RetryState {
	attempt: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error?: any;
	lastDelay?: number;
}

const defaultRetryConfig: RetryConfig = {
	maxRetries: 3,
	initialDelay: 1000,
	maxDelay: 10000,
	retryableStatuses: [408, 429, 500, 502, 503, 504],
	retryableErrors: ['Failed to fetch', 'NetworkError', 'Network request failed', 'net::ERR_INTERNET_DISCONNECTED', 'net::ERR_CONNECTION_TIMED_OUT', 'The Internet connection appears to be offline', 'A network error occurred'],
	checkOnlineStatus: true,
	showUserFeedback: true,
};
export interface fetchError {
    success: false;
    code: string;
    message: string;
    data: unknown;
}

export interface fetchSuccess<Type> {
    success: true;
    code: string;
    message: string;
    data: Type;
}

interface simpleFetchParams {
    url: string;
    headers?: RequestInit['headers'];
    method: RequestInit['method'];
    mode?: RequestInit['mode'];
    credentials?: RequestInit['credentials'];
    params?: string | Record<string, unknown>;
}


const defaultSimpleFetch = <Type>({
    url,
    headers,
    method,
    mode,
    credentials,
    params,
}: simpleFetchParams): Promise<fetchSuccess<Type> | fetchError> => {
    const body: Record<string, string> = method == 'POST' ? { body: JSON.stringify(params) } : {};

    url = method === 'GET' && typeof params === 'object' ? `${url}?${new URLSearchParams(params as Record<string, string>).toString()}` : typeof params === 'string' ? `${url}/${params}` : url;

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...headers,
        },
        credentials: credentials ?? 'include',
        mode: mode ?? 'cors',
        method,
        ...body,
    })
		.then((response) => {
			if (response.status === 210) {
				localStorage.setItem('SSO_CREDENTIALS', JSON.stringify(`${response.headers.get('RefreshedToken')}`));
			}
            
			return response.json();
		})
        .then((response): fetchSuccess<Type> | fetchError => {
            if (!response.success && response.message === 'AuthenticationError') {
                handleRedirectSSO();
            }

            if (response.success) {
                return {
                    success: true,
                    code: response?.code || 'OK',
                    message: response?.message || '',
                    data: response?.data as Type,
                };
            } else {
                return {
                    success: false,
                    code: response?.code || 'UNKNOWN_ERROR',
                    message: response?.message || 'An unknown error occurred',
                    data: response?.data || '',
                };
            }

        })
        .catch((error: Error) : fetchError => {
            return {
                success: false,
                code: 'UNKNOKN ERROR',
                message: 'An unknown error occurred',
                data: error ?? 'Bad request',
            };
        });
};

export const withRetry = (fetchFn: typeof defaultSimpleFetch, retryConfig: RetryConfig = {}) => {
	const config = { ...defaultRetryConfig, ...retryConfig };

	const waitForOnline = async (): Promise<void> => {
		if (!navigator?.onLine) {
			if (config.showUserFeedback) {
				toast.warning('Esperando por conexión de internet...');
			}

			return new Promise((resolve) => {
				const onlineHandler = () => {
					window.removeEventListener('online', onlineHandler);

					if (config.showUserFeedback) {
						toast.info('Conexión reestablecida reintentando...');
					}

					resolve();
				};

				window.addEventListener('online', onlineHandler);
			});
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const shouldRetry = (error: any, state: RetryState): boolean => {
		if (state.attempt >= (config.maxRetries ?? 0)) {
			return false;
		}

		// Check for retryable HTTP status codes
		if (error?.status && config.retryableStatuses?.includes(error.status)) {
			return true;
		}

		// Check for retryable error messages
		const errorMessage = String(error).toLowerCase();
		return config.retryableErrors?.some((e) => errorMessage.includes(e.toLowerCase())) ?? false;
	};

	const getDelay = (state: RetryState): number => {
		const initialDelay = config.initialDelay ?? 1000;
		const maxDelay = config.maxDelay ?? 10000;

		// Exponential backoff with jitter
		const exponentialDelay = initialDelay * Math.pow(2, state.attempt - 1);
		const jitter = Math.random() * 0.25 * exponentialDelay; // 25% jitter
		return Math.min(exponentialDelay + jitter, maxDelay);
	};

	return async <T = unknown>(params: Parameters<typeof fetchFn<T>>[0]) => {
		const state: RetryState = { attempt: 1 };

		// eslint-disable-next-line no-constant-condition
		while (true) {
			try {
				// Check online status if enabled
				if (config.checkOnlineStatus && typeof navigator !== 'undefined') {
					await waitForOnline();
				}

				const result = await fetchFn<T>(params);

				// If the request was successful, return the result
				if (result.success) {
					// Clear any error messages if they exist
					if (config.showUserFeedback && state.attempt > 1) {
						toast.success('Conexión restablecida exitosamente!');
					}
					return result;
				}

				// If it's an auth error, don't retry
				if (result.data === 'AuthenticationError') {
					return result;
				}

				// Treat failed requests as errors that might need retry
				throw new Error(result.message);
			} catch (error) {
				state.error = error;

				if (shouldRetry(error, state)) {
					state.lastDelay = getDelay(state);

					if (config.showUserFeedback) {
						toast.error(`Petición fallida. Reintentando en ${Math.round(state.lastDelay / 1000)}s... ` + `(Intento ${state.attempt} de ${config.maxRetries})`);
					}

					await new Promise((resolve) => setTimeout(resolve, state.lastDelay));
					state.attempt++;
					continue;
				}

				// If we shouldn't retry, return a failed response
				if (config.showUserFeedback) {
					toast.error('Petición fallida. Intenta más tarde por favor.');
				}

				return {
					success: false as const,
					data: String(error).split(': ')[1] ?? String(error),
				} as fetchError;
			}
		}
	};
};



export const simpleFetch = withRetry(defaultSimpleFetch);